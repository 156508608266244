// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query TenantSettings {
    settings {
      tenantSettings: tenant {
        authMethods: auth_methods
        theme {
            primary_color
            primary_background_color
            primary_panel_background_color
            secondary_panel_background_color
            primary_text_color
            secondary_text_color
            primary_link_color
            primary_list_link_color
            primary_button_color
            primary_button_text_color
            logo_main
            logo_login
        }
        locale
        datetimeLocale: datetime_locale
        compactMode: compact_mode
      }
      genericTranslation: generic_translations
    }
  }
`;

export default QUERY;
